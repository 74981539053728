<template>
    <v-card elevation="3" v-bind="$attrs">
        <v-list shaped>
            <div class="d-flex align-center justify-space-between mr-2 ml-3 my-2">
                <div dense v-text="field" class="mr-2 font-weight-bold text-capitalize"></div>
                <div dense v-text="data[`${field}_total`]" class="font-weight-bold"></div>
            </div>
            <v-list-item-group dense>
                <v-list-item  v-for="(item, i) in data.tooltip" :key="i" dense class="pa-0 pl-1">
                    <v-list-item-icon dense class="mr-3">
                        <v-icon :color="item.color" v-text="item.icon" class="ma-0 pa-0"></v-icon>
                    </v-list-item-icon>
                    <v-list-item-content dense>
                        <div class="d-flex align-center justify-space-between">
                            <div dense v-text="item.text" class="mr-5"></div>
                            <div dense v-text="item[field]"></div>
                        </div>
                    </v-list-item-content>
                </v-list-item>
            </v-list-item-group>
        </v-list>
    </v-card>
</template>

<script>
    export default {
        props: {
            field: {
                type: String,
                default: 'dent'
            },
            data: {
                type: Object,
                default: () => ({})
            }
        },
    }
</script>

<style lang="scss" scoped>

</style>